import Sections from '../components/Sections'
import React from 'react'
import { Box, Accent } from '../components/Elements'
import { FormInput, FormSelect } from '../components/Forms/Inputs'
import { TypesOfSection } from '../lib/Constants'
export default function UseCreateSections(sections) {

    // console.log(sections)
    // return an array of sections
    if (!sections) return DefaultSections


    // Map over sections and use the type to define which section to pass the data to
    return sections.map((section, index) => {

        // console.log(section.type)
        const type = section.type === "ContentfulHeader" ? "Header" : section.type

        // Handling the next section ID fro the header component
        const nextSectionId = section.type === "ContentfulHeader" ? sections.length > (index + 1) && sections[index+1].id : null

        // Creating props
        const props = nextSectionId ? {
            ...section,
            nextSectionId
        } : section
        return () => TypesOfSection[type](props)
    })
}






const DefaultSections = [
    // Define next services by next section in the array of sections 
    <Sections.Header nextSectionId="#services" />,

    /// Services  
    <Sections.Services />,


    // Case Studies 
    <Sections.CaseStudies />,

    // About 
    <Sections.About img={true} />,

    // Testimonial 
    <Sections.Testimonials />,


    // Projects 
    <Sections.Projects />,



    // Contact me 
    <Sections.Section className="flex flex-col md:flex-row w-full" id="contact-me" >
        <div className="flex flex-col justify-center order-2 w-full md:w-1/2">
            <form className="text-gray-100 relative z-10 w-full" >
                <div className="p-2 md:p-4 px-5 md:px-8 bg-black z-10 relative">
                    <FormInput type="text" name="Name" label="Your Name" placeholder="First Last" />
                    <FormInput type="email" name="Email" label="Your Email" placeholder="email@example.com" />
                    <FormSelect name="Service" label="Enquiry" options={["General", "Option 2", "option3"]} />
                    <FormInput textArea name="Message" label="Your Message" placeholder={`Hi there, \nI would love to work together on a project. Could we jump on a call to discuss it?`} />
                    <FormInput
                        type="submit"
                        name="submit"
                        button
                        inputProps={{ children: "Send Enquiry", className: "bg-gray-700 text-gray-100" }}
                    />
                </div>
                <Box className="bg-accent z-0" top />
            </form>
        </div>

        <div className="flex flex-col justify-center md:w-1/2 order-1 md:order-2 mb-12 md:mb-0 lg:ml-12">
            <div className="p-4 relative">
                <h2 className="text-3xl">Work with me</h2>
                <p>If you want to use this template you can find it here.</p>
                <Accent />
            </div>
        </div>
    </Sections.Section>
]