import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import UseCreateSections from "../hooks/UseCreateSections"


const IndexPage = ({ data: { IndexPage } }) => {
  // Add the data to the SEO for the page
  const navLinks = IndexPage.menu.map(item => ({ name: item.contentfulid, linkTo: `#${item.contentfulid.trim().replace(/ /, "-")}` }))
  // Find the first section to allow people to scroll home to
  const {
    // path = "/", 
    title = null, description: { text = null }, image, sections } = IndexPage

  const homeLink = { name: "Home", linkTo: `#${sections ? sections[0].id : ""}` }

  const SectionsToRender = UseCreateSections(sections)

  return (
    <>
      <SEO title={title} description={text} image={image.sizes.src} offSiteImage />
      <Layout homeLink={homeLink} navLinks={navLinks}>
        {SectionsToRender.map((Section, index) => <Section key={`${Section.id} + ${index}`} />)}
      </Layout>
    </>
  )
}




export const IndexPageQuery = graphql`
query IndexPageQuery {
  IndexPage: contentfulPage(path: {eq: "/"}) {
    path
    title
    description {
      text: description
    }
    image {
      sizes(maxWidth: 200, quality: 90) {
        src
      }
    }
    menu {
      contentfulid
    }
    sections {
      ... on ContentfulHeader {
        id: contentfulid
        header
        subHeader
        type:  __typename
      }
      ... on ContentfulSection {
        id: contentfulid
        type
        title
        content {
          ... on ContentfulBlock{
            header
            description {
              text: description
            }
            linkTo
            image {
              title
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_tracedSVG
              }
              logo: fluid(maxWidth: 200, maxHeight: 160) {
                ...GatsbyContentfulFluid
              }
              svg: file {
                url
              }
            }
          }
          ... on ContentfulForm {
            formName
            successMessage
            submitButtonText
            inputs {
              name
              label
              placeholder
              selectOptions
              type
            }
          }
        }
      }
    }
  }
}

`


export default IndexPage
